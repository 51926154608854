import Cookie from 'universal-cookie';
import React from 'react';
import { User } from './components/types';

const friendlyOrgNames: { [key: string]: string } = {
    "digitalnest.org": "Digital Nest",
    "thedevdifference.com": "The Dev Difference"
  };

  const primaryRoles: string[] = ["software engineering", "product management"];

export function getCookie(cookieName: string): string | null {
  const cookie = new Cookie();
  const token = cookie.get(cookieName);

  if (token === undefined) {
    return null;
  }
  return token;
}

export function hasMultipleQuestionPacks(role: string, type: string): boolean {
  return (type === "behavioral" || (type === "technical" && primaryRoles.includes(role)));
}

export function parseQuestionQuery(query: string): { [key: string]: string } {
  let sections = query.split("+");
  let parsed: { [key: string]: string } = {};
  for (let i = 0; i < sections.length; i++) {
    let item = sections[i].split("=");
    parsed[item[0]] = item[1];
  }
  return parsed;
}

export function getPackName(role: string, type: string, specType: string, domain: string, user: User): string {
  if (primaryRoles.includes(role) && type === "technical") {
    return (specType);
  }
  else if (!primaryRoles.includes(role) && type === "technical") {
    return (role);
  }
  else if (type === "behavioral") {
    return (specType === 'general' ? "Essential 10" : specType === 'role' ? role : "Resume Based")
  }
  else if (type === "domain") {
    return domain;
  }
  else if (type === "org") {
    return capitalizeFirstLetter(user.organization || '');
  }
  else if (type === "jobDescriptionQ") {
    return "Job Description";
  }
  else if (type === "custom") {
    return "Custom Question";
  }
  return '';
}

export function capitalizeFirstLetter(word: string): string {
  const l = word.split(' ');
  const newl = l.map(x => x.charAt(0).toUpperCase() + x.slice(1))
  return newl.join(' ');
}

export function hasPremSub(user: User | null): boolean {
  if (user && !user.isLegacyUser) {
    return user.hasActiveSubscription;
  }
  return true;
}

export function hasNonTrialPremSub(user: User | null): boolean {
  return true;
}

export const GetFriendlyOrgNames = (org: string | null | undefined): string =>{
    if (org !== null && org !== undefined){
        let name = friendlyOrgNames[org];
        return name !== undefined ? name : org;
    }
    return "";
}

export function getTimestamp(): string {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth() + 1;
    const day = now.getDate();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  
export async function feedbackMap(feedback: string): Promise<[string[], string[], string[]] | null> {
  let feedA: any[] 
  try {
    let newf: any = await JSON.parse(feedback);
    feedA = newf["feedback"];
    if(feedA== undefined)
    {
      throw new Error("Feedback array is undefined");
    }
  }
  catch (e) {
    //fall back for old feedback that wasn't structured gpt output
    try{
      let newf: string = feedback.replace(/\\"/g, '\"').replace(/\\n/g, '   ').replace(/^\"/, '').replace(/\"$/, '').replace(/\\/g, '');
      if (newf.startsWith("```json")) {
          newf = newf.substring(7, newf.length - 4);
      }
      feedA = await JSON.parse(newf);
    }
    catch (e) {
      console.log("Error in feedbackMap:", e);
      return null;
    }
  }

    let feedbackGrade: string[] = [];
    let feedbackCriteria: string[] = [];
    let criteriaCont: string[] = [];

    const mapping: { [key: string]: string } = {
        "Poor": "Poor",
        "Satisfactory": "Satisfactory",
        "Fair": "Satisfactory",
        "Good": "Good",
        "Excellent": "Exceptional"
    };
    const kudos: { [key: string]: string } = {
        "Poor": " <br/><br/>You tried your best, but there are some things you need to work on. Keep practicing!",
        "Good": "<br/><br/>Good answer! With a bit more practice, your answers will be exceptional.",
        "Fair": "<br/><br/>Not bad! With some more practice, your answers will be exceptional.",
        "Satisfactory": "<br/><br/>Not bad! With some more practice, your answers will be exceptional.",
        "Excellent": " <br/><br/>Well done! This is an answer a skilled candidate would give."
    };

    //validate feedback grade and details
    for (let i = 0; i < feedA.length; i++) {
        let grade: string = feedA[i]["Grade"];
        let content: string = feedA[i]["Details"];
        if (!(grade in mapping)) {
          feedA[i]["Grade"] = "Poor";
        }
        if(!content){
          feedA[i]["Details"] = "No evaluation available.";
        }
    }
    for (let i = 0; i < feedA.length; i++) {
        feedbackCriteria.push(feedA[i]["Criteria"]);
        let grade: string = feedA[i]["Grade"];
        let cont: string = feedA[i]["Details"].replace(/\"/g, '').replace(/   /g, '<br/>').replace(/\\/g, '');
        feedbackGrade.push(grade in mapping ? mapping[grade] : "Poor");
        criteriaCont.push(grade in kudos ? cont + kudos[grade] : cont + kudos["Poor"]);
    }
    return [feedbackCriteria, feedbackGrade, criteriaCont];
}

export function feedbackMap_OG(feedback: string): [string[], string[], string[]] | null {
    let contentArr: string[] = [];
    let ind: number = 1;
    let match0: RegExpMatchArray | null;
    let toMatch: string = feedback;
    while(ind > 0){
        let pattern: RegExp = new RegExp(`${ind}\\.(.*?)<br/>${ind+1}`);
        match0 = toMatch.match(pattern);
        if (match0 && match0[1]) {
            contentArr.push(match0[1]);
        }
        else{
            pattern = new RegExp(`${ind}\\)(.*?)<br/>${ind+1}`);
            match0 = toMatch.match(pattern);
            if (match0 && match0[1]) {
                contentArr.push(match0[1]);
            }
            else{
                break;
            }
        }
        ind++;
    }
    if(ind>1)
    {
        let p: RegExp = new RegExp(`<br/>${ind}\\.(.*?)$`);
        match0 = feedback.match(p);
        if (match0 && match0[1]) {
            let i: number = match0[1].lastIndexOf("<br/><br/>Overall,");
            contentArr.push(i !== -1 ? match0[1].slice(0,i): match0[1]);
        }
    }

    if (ind === 1){
        let pattern: RegExp = /1\.(.*?)$/;
        match0 = feedback.match(pattern);
        if (match0 && match0[1]) {
            let i: number = match0[1].lastIndexOf("<br/><br/>Overall,");
            contentArr.push(i !== -1 ? match0[1].slice(0,i): match0[1]);
        }
    }

    if(contentArr.length === 0){
        return null;
    }

    let feedbackGrade: string[] = [];
    let feedbackCriteria: string[] = [];
    let criteriaCont: string[] = [];
    const mapping: {[key: string]: string} = {
        "Poor": "Poor",
        "Satisfactory": "Satisfactory",
        "Fair":"Satisfactory",
        "Good":"Good",
        "Excellent" : "Exceptional"
    };
    const kudos: {[key: string]: string} = {
        "Poor": " <br/><br/>You tried your best, but there are some things you need to work on. Keep practicing!",
        "Good":"<br/><br/>Good answer! With a bit more practice, your answers will be exceptional.",
        "Fair": "<br/><br/>Not bad! With some more practice, your answers will be exceptional.",
        "Satisfactory": "<br/><br/>Not bad! With some more practice, your answers will be exceptional.",
        "Excellent" : " <br/><br/>Well done! This is an answer a skilled candidate would give."
    };
    contentArr.forEach((text: string) => {
        if(!/^\s*$/.test(text))
        {
            //extract heading
            let pattern: RegExp = /\*\*(.*?)\*\*/;

            const match: RegExpMatchArray | null = text.match(pattern);

            if (match && match[1]) {
                let cont: string = "";
                let grade: string = "";
                let heading: string = match[1];
                pattern = /Grade: (.*?)\<br\/\>/;

                const match2: RegExpMatchArray | null = text.match(pattern);
                
                if (match2 && match2[1]) {
                    grade = match2[1];
                }
                pattern = /Detailed Evaluation:(.*)/;
                const match3: RegExpMatchArray | null = text.match(pattern);
                if (match3 && match3[1]) {
                    cont = match3[1];
                    cont = cont[0] === '<' ? cont.slice(5) : cont;
                }

                if(cont!=="")
                {
                    feedbackCriteria.push(heading);
                    feedbackGrade.push(grade in mapping ? mapping[grade] : "Poor");
                    criteriaCont.push(grade in kudos ?  cont + kudos[grade] : cont + kudos["Poor"]);
                }
                else if(grade.split(' ').length > 3)
                {
                    feedbackCriteria.push(heading);
                    feedbackGrade.push("Poor");
                    criteriaCont.push(grade);
                }
                else if(grade!== ""){
                    pattern = new RegExp(`Grade: ${grade}<br/>(.*?)$`);
                    const match4: RegExpMatchArray | null = text.match(pattern);
                    if (match4 && match4[1]) {
                        cont = match4[1];
                        cont = cont[0] === '<' ? cont.slice(5) : cont;
                        feedbackCriteria.push(heading);
                        feedbackGrade.push(grade in mapping ? mapping[grade] : "Poor");
                        criteriaCont.push(grade in kudos ?  cont + kudos[grade] :  cont + kudos["Poor"]);
                    }
                }
            }
        }
    });
    return feedbackCriteria.length === 0 ? null : [feedbackCriteria, feedbackGrade, criteriaCont];
}

export function generateGUID(): string {
  const randomBytes = new Uint8Array(16);
  crypto.getRandomValues(randomBytes);

  randomBytes[6] = (randomBytes[6] & 0x0f) | 0x40;
  randomBytes[8] = (randomBytes[8] & 0x3f) | 0x80;

  let uuid = '';
  for (let i = 0; i < 16; i++) {
    uuid += (randomBytes[i] + 0x100).toString(16).substr(1);
    if ([3, 5, 7, 9].includes(i)) {
      uuid += '-';
    }
  }

  return uuid;
}

export function GetQuestionDisplay(question: string, qLevel: string | null = null): JSX.Element[] {
  try {
    let contentArr = question.split('<br/>');
    let questionFrags = contentArr.map((item, index) => {
      if (!/^\s*$/.test(item)) {
        return (
          <React.Fragment key={generateGUID()}>
            {index === (contentArr.length - 1) && qLevel !== null && <>{item} <span className="levelTag"><span id={qLevel} /><b className="pl-2">{qLevel}</b></span></>}
            {(index !== contentArr.length - 1 || qLevel === null) && item}
            {index !== contentArr.length - 1 && <br />}
          </React.Fragment>
        );
      } else {
        return (<React.Fragment key={generateGUID()}></React.Fragment>)
      }
    });
    return questionFrags
  }
  catch {
    return [];
  }
}
